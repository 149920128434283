import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { visuallyHidden } from '@mui/utils';
import { styled, keyframes } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 700,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  boxShadow:
    theme.palette.mode === 'light'
      ? '0 0 12px 8px hsla(120, 25%, 80%, 0.2)'  // Updated to green
      : '0 0 24px 12px hsla(120, 100%, 25%, 0.2)',  // Updated to green
  outline: '1px solid',
  backgroundImage: `url(${
    theme.palette.mode === 'light'
      ? '/static/images/templates/templates-images/hero-light.png'
      : '/static/images/templates/templates-images/hero-dark.png'
  })`,
  backgroundSize: 'cover',
  outlineColor:
    theme.palette.mode === 'light'
      ? 'hsla(120, 25%, 80%, 0.5)'  // Updated to green
      : 'hsla(120, 100%, 80%, 0.1)',  // Updated to green
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative', // Added for centering content
}));

const IconContainer = styled('div')(({ theme }) => ({
  width: '150px',
  height: '150px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '& img': {
    position: 'absolute',
    width: '150px',  // Set the size of the icons
    height: '150px',  // Set the size of the icons
    animation: `${fadeInOut} 1.5s infinite`, // Slowed down the animation speed to 1.5s
  },
}));

const icons = Array.from({ length: 22 }, (_, i) => `/icons/icon${i + 1}.svg`);

const Hero = () => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState('');
  const [currentIcon, setCurrentIcon] = useState(icons[0]);

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setCurrentIcon(icons[Math.floor(Math.random() * icons.length)]);
      }, 1500); // Change icon every 1.5s for slower transition
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [loading]);

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setResults('');
    try {
      const response = await axios({
        method: 'post',
        url: 'https://api.openai.com/v1/chat/completions',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        },
        data: {
          model: 'gpt-4',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: `Suggest a healthier alternative to ${query} based on its nutritional value and explain how the suggested alternative is better.` }
          ],
          max_tokens: 150
        }
      });
      const nutritionalValue = response.data.choices[0].message.content.trim();
      setLoading(false);
      setResults(nutritionalValue);
    } catch (error) {
      setLoading(false);
      setError('Error fetching data from API');
      console.error('Error fetching data from API:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(120, 100%, 90%), transparent)'  // Updated to green
            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(120, 100%, 16%), transparent)',  // Updated to green
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            EAT&nbsp;THIS&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'inherit',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              INSTEAD
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ width: { sm: '100%', md: '80%' } }}
          >
            A healthier alternative to the searched food item...
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <InputLabel htmlFor="search-hero" sx={visuallyHidden}>
              Search
            </InputLabel>
            <TextField
              id="search-hero"
              hiddenLabel
              size="small"
              aria-label="Enter a food item"
              placeholder="Enter a food item"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              disabled={loading}
            />
            <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Search'}
            </Button>
          </Stack>
          {error && <Typography color="error">{error}</Typography>}
        </Stack>
        <StyledBox id="image">
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <IconContainer>
                <img src={currentIcon} alt="Loading icon" />
              </IconContainer>
              <Box sx={{ mt: 2, width: '80%' }}>
                <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 1 }} />
              </Box>
            </Box>
          ) : (
            results && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: '100%',
                  p: 2,
                  width: '100%',
                  maxWidth: '600px',
                }}
              >
                <Typography variant="body1" sx={{ textAlign: 'center' }}>{results}</Typography>
              </Box>
            )
          )}
        </StyledBox>
      </Container>
    </Box>
  );
}

export default Hero;
